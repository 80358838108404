import React, {
  useState,
  Fragment,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react'
import { Input, Button, Modal, Icon, ContextProps } from '@jdlt-ltd/pongo'
import {
  ChangeEventValue,
  containsValidS3FolderChars,
  containsValidS3FileChars,
  FileOrFolder
} from '../../utils'

type EditableInputProps = {
  name: string
  type?: string
  handleSaveName: Function
  handleOverwrite: Function
  handleCancelUpload: Function
  currentFolderData: FileOrFolder[]
  parentFolder: string
}

const ExclamationIcon = ({ isError }: { isError: boolean }) => {
  return isError ? (
    <Icon
      iconName={'exclamation'}
      className="w-5 h-5 text-red-500"
      aria-hidden="true"
    />
  ) : null
}

export const EditableUploadFile: React.FC<EditableInputProps> = ({
  name,
  type = `folder`,
  handleSaveName,
  handleOverwrite,
  handleCancelUpload,
  currentFolderData,
  parentFolder
}: EditableInputProps) => {
  const regExToUse =
    type === `folder` ? containsValidS3FolderChars : containsValidS3FileChars

  const [updatedName, setUpdatedName] = useState<string>(name)
  const [invalidCharacter, setInvalidCharacter] = useState<string | null>(null)
  const [isNameInCurrentFolderData, setIsNameInCurrentFolderData] =
    useState<boolean>(true)

  const originalName = useRef(name)
  const timeOut = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const isName = currentFolderData.some(({ name }) => name === updatedName)
    setIsNameInCurrentFolderData(isName)
  }, [updatedName, currentFolderData])

  const handleNameChange = (value: string | number): void => {
    const stringifiedName = value.toString()
    if (regExToUse(stringifiedName)) {
      setUpdatedName(stringifiedName)
    } else {
      timeOut.current && clearTimeout(timeOut.current)
      const invalidCharacter = stringifiedName
        .split('')
        .filter((char) => !regExToUse(char))
      setInvalidCharacter(`${invalidCharacter}`)
      timeOut.current = setTimeout(() => setInvalidCharacter(null), 2000)
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const trimmedName = updatedName.trim()
    if (trimmedName.length > 0 && trimmedName !== originalName.current) {
      handleSaveName(trimmedName, originalName.current)
    }
  }
  const handleClickOverWrite = (e: any) => {
    e.preventDefault()
    handleOverwrite()
  }

  const handleCancel = (e: any) => {
    e.preventDefault()
    handleCancelUpload()
  }

  return (
    <Fragment>
      <form className="mt-5">
        <div>
          <div className="relative mt-1 rounded-md shadow-sm">
            <Input
              isError={
                originalName.current === updatedName ||
                isNameInCurrentFolderData
              }
              onChange={(
                _e: React.ChangeEvent,
                { value }: ChangeEventValue
              ): void => {
                handleNameChange(value)
              }}
              value={updatedName}
              autoFocus
              placeholder={originalName.current}
              iconRight={
                <ExclamationIcon
                  isError={
                    originalName.current === updatedName ||
                    isNameInCurrentFolderData
                  }
                />
              }
            />
          </div>
          {(originalName.current === updatedName ||
            isNameInCurrentFolderData) && (
            <p className="mt-2 text-sm text-red-600" id="same-file-name-error">
              Folder {parentFolder} already contains a file named{' '}
              {originalName.current === updatedName
                ? originalName.current
                : updatedName}
            </p>
          )}
          {invalidCharacter && (
            <div id="error-message" className="mt-2 text-sm text-red-600">
              <kbd className="text-red-500 bg-gray-50 text-sm leading-5 py-0.5 px-1.5 border border-red-300 rounded-md border-b-2">
                {invalidCharacter}
              </kbd>{' '}
              is not a valid character
            </div>
          )}
        </div>

        <div className="flex justify-between mt-3">
          <div className="space-x-2">
            <span className="rounded-md shadow-sm sm:mt-0 sm:w-auto">
              <Button
                type="submit"
                content="Rename"
                disabled={
                  !updatedName ||
                  updatedName === originalName.current ||
                  isNameInCurrentFolderData
                }
                onClick={handleSubmit}
              />
            </span>
            <span className="rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto">
              <Button
                type="submit"
                content="Overwrite"
                onClick={handleClickOverWrite}
              />
            </span>
          </div>
          <span className="rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto">
            <Button type="submit" content="Cancel" onClick={handleCancel} />
          </span>
        </div>
      </form>
    </Fragment>
  )
}

type EditUploadProps = {
  duplicatefileToUpload: File
  setFileUpdate: (arg: File) => void
  setOverwriteFile: (arg: File) => void
  cancelFileUploadUpdate: (arg: File) => void
  currentFolderData: FileOrFolder[]
  parentFolder: string
}

export const EditUploadModal: React.FC<EditUploadProps> = ({
  duplicatefileToUpload,
  setFileUpdate,
  setOverwriteFile,
  cancelFileUploadUpdate,
  currentFolderData,
  parentFolder
}: EditUploadProps) => {
  const [modalOpen, setModalOpen] = useState(true)

  const handleOverWrite = () => {
    setOverwriteFile(duplicatefileToUpload)
    setModalOpen(false)
  }

  const handleCancelUpload = () => {
    setModalOpen(false)
    cancelFileUploadUpdate(duplicatefileToUpload)
  }

  const handleRename = (originalName: string) => {
    setFileUpdate(new File([duplicatefileToUpload], originalName))
    setModalOpen(false)
  }

  return (
    <Modal
      key={JSON.stringify(duplicatefileToUpload)}
      visible={modalOpen}
      closeOnEsc={true}
      closable={true}
      onClose={handleCancelUpload}
    >
      <EditableUploadFile
        handleCancelUpload={handleCancelUpload}
        name={duplicatefileToUpload.name}
        handleOverwrite={handleOverWrite}
        handleSaveName={handleRename}
        type={'string'}
        currentFolderData={currentFolderData}
        parentFolder={parentFolder}
      />
    </Modal>
  )
}

type ExistingFilesUploadType = {
  duplicatefilesToUpload: File[] | null
  setDuplicateFilesToUpload: Dispatch<SetStateAction<File[] | null>>
  setOverWrite: Dispatch<SetStateAction<File[] | null>>
  setFilesUpload: Dispatch<SetStateAction<File[] | null>>
  currentFolderData: FileOrFolder[]
  path: string
  activeClient: ContextProps | null
}

export const EditableUpload: React.FC<ExistingFilesUploadType> = ({
  duplicatefilesToUpload,
  setDuplicateFilesToUpload,
  setOverWrite,
  setFilesUpload,
  currentFolderData,
  path,
  activeClient
}: ExistingFilesUploadType) => {
  const editFileNameModal = duplicatefilesToUpload?.map(
    (duplicatefileToUpload: File, i: number) => {
      return (
        <EditUploadModal
          key={`${JSON.stringify(duplicatefilesToUpload)}-${i}`}
          currentFolderData={currentFolderData}
          duplicatefileToUpload={duplicatefileToUpload}
          parentFolder={
            path.split('/')[path.split('/').length - 1] ||
            activeClient?.name ||
            ''
          }
          cancelFileUploadUpdate={(file: File) => {
            setDuplicateFilesToUpload(
              (currentState) =>
                currentState?.filter(({ name }) => name !== file.name) ||
                currentState
            )
          }}
          setOverwriteFile={(file: File) => {
            setDuplicateFilesToUpload(
              (currentState) =>
                currentState?.filter(({ name }) => name !== file.name) ||
                currentState
            )
            setOverWrite((currentFiles) =>
              currentFiles ? [...currentFiles, file] : [file]
            )
          }}
          setFileUpdate={(file: File) => {
            setDuplicateFilesToUpload(
              (currentState) =>
                currentState?.filter(
                  ({ name }) => name !== duplicatefileToUpload.name
                ) || currentState
            )
            setFilesUpload((currentFiles) =>
              currentFiles ? [...currentFiles, file] : [file]
            )
          }}
        />
      )
    }
  )

  return <>{editFileNameModal}</>
}
