import React from 'react'
import { ClientContextSwitcher } from './index'
import { Avatar, getClassName } from '@jdlt-ltd/pongo'
import { MenuOption } from '../utils'
import { useAuth0 } from '@auth0/auth0-react'

const DisplayMenuOption = ({ name, onClick, isActive }: MenuOption) => {
  return (
    <span
      onClick={onClick ? () => onClick() : undefined}
      className={getClassName([
        'block',
        'px-4',
        'py-2',
        'text-base',
        'font-medium',
        'transition',
        'duration-150',
        'ease-in-out',
        [
          !!isActive,
          ['outline-none', 'text-gray-800', 'bg-gray-100'],
          ['text-gray-500', 'hover:text-gray-800', 'hover:bg-gray-100']
        ]
      ])}
      role="menuitem"
    >
      {name}
    </span>
  )
}

export const UserMenu: React.FC<{ menuOptions: MenuOption[] }> = ({
  menuOptions
}: {
  menuOptions: MenuOption[]
}) => {
  const { user } = useAuth0()

  return (
    <>
      <div className="mb-4 ml-4">
        <ClientContextSwitcher
          cssClasses={['max-w-xs', 'truncate', 'inline-block']}
        />
      </div>
      <div className="pt-4 pb-3 border-t border-gray-200">
        <div className="flex items-center px-4">
          <div className="flex-shrink-0">
            <Avatar src={user?.picture as string} />
          </div>
          <div className="ml-3">
            <div className="text-base font-medium leading-6 text-gray-800">
              {user?.name}
            </div>
            <div className="text-sm font-medium leading-5 text-gray-500">
              {user?.email !== user?.name && (user?.email as string)}
            </div>
          </div>
        </div>
      </div>
      <div
        className="mt-3"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
      >
        {menuOptions.map(({ name, onClick, isActive }, index) => (
          <DisplayMenuOption
            key={`${name}-${index}`}
            name={name}
            onClick={onClick}
            isActive={isActive}
          />
        ))}
      </div>
    </>
  )
}
