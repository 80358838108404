import { assign, createMachine } from 'xstate'
import { getFileExtension } from '..'

export type File = { fileUrl: string; fileName: string; isLoaded: boolean }

export interface CheckMediaLoadMachineContext {
  file: File
  errorMessage?: string
}

export type CheckMediaLoadMachineEvent = {
  type: 'LOAD' | 'COMPLETE' | 'FAIL'
  variables: { file: File }
}

export const imageFileExtensionSupported = [
  'png',
  'jpeg',
  'tiff',
  'jpg',
  'gif',
  'ico',
  'svg',
  'webp'
]

const mediaFileExtensionSupported = [
  'opus',
  'mp3',
  'wav',
  'avi',
  'ogv',
  'ogm',
  'ogg',
  'mp4',
  'webm',
  'mov'
]
const isMedia = (file: File) => {
  const { fileName } = file
  const fileExtension = getFileExtension(fileName)
  return [
    ...imageFileExtensionSupported,
    ...mediaFileExtensionSupported
  ].includes(fileExtension)
}

const isTextFile = (file: File) => {
  const { fileName } = file
  const fileExtension = getFileExtension(fileName)
  return fileExtension === 'txt'
}

const isSupported = (file: File) => {
  const { fileName } = file
  const fileExtension = getFileExtension(fileName)
  return fileExtension === 'pdf'
}

const isNotSupported = (file: File) => {
  const { fileName } = file
  const fileExtension = getFileExtension(fileName)
  return ![
    ...imageFileExtensionSupported,
    ...mediaFileExtensionSupported,
    'pdf'
  ].includes(fileExtension)
}

export const CheckMediaLoadMachine = createMachine<
  CheckMediaLoadMachineContext,
  CheckMediaLoadMachineEvent
>(
  {
    id: 'fetchUrlToViewFileMachine',
    initial: 'idle',
    states: {
      idle: {
        on: {
          LOAD: {
            target: 'loading',
            actions: 'assignFile'
          }
        }
      },
      loading: {
        always: [
          { target: 'checkingBrowserSupport', cond: 'isMedia' },
          { target: 'loaded', cond: 'isSupported' },
          { target: 'failed', cond: 'isNotSupported' }
        ]
      },
      loaded: {
        always: [{ target: 'complete', actions: 'assignIsLoaded' }]
      },
      checkingBrowserSupport: {
        on: {
          COMPLETE: {
            target: 'complete',
            actions: 'assignFileBrowserSupport'
          },
          FAIL: 'failed'
        }
      },
      failed: {
        always: [
          {
            target: 'complete',
            actions: 'assignIsFailed',
            cond: 'isNotTextFile'
          },
          {
            target: 'getText',
            cond: 'isTextFile'
          }
        ]
      },
      getText: {
        invoke: {
          src: 'getText',
          onDone: {
            target: 'complete',
            actions: ['saveText', 'assignIsLoaded']
          },
          onError: {
            target: 'errored',
            actions: 'assignErrorToContext'
          }
        }
      },
      complete: { type: 'final' },
      errored: {
        on: { LOAD: { target: 'idle', actions: 'clearErrorMessage' } }
      }
    }
  },
  {
    actions: {
      assignFile: assign({
        file: (_context, event: any) => event.file
      }),
      assignFileBrowserSupport: assign({
        file: (context, event: any) => {
          const { file } = context
          return { ...file, isLoaded: event.isLoaded }
        }
      }),
      assignIsLoaded: assign({
        file: (context, event) => {
          const { file } = context
          return { ...file, isLoaded: true }
        }
      }),
      assignIsFailed: assign({
        file: (context, event) => {
          const { file } = context
          return { ...file, isLoaded: false }
        }
      }),
      saveText: assign({
        file: (context, event: any) => ({
          ...context.file,
          content: event.data
        })
      }),
      assignErrorToContext: assign({
        errorMessage: (_context, event: any) =>
          event.data[0]?.message || 'An unknown error occurred'
      }),
      clearErrorMessage: assign({
        errorMessage: (_context) => undefined
      })
    },
    guards: {
      isMedia: (context) => isMedia(context.file),
      isSupported: (context) => isSupported(context.file),
      isNotSupported: (context) => isNotSupported(context.file),
      isTextFile: (context) => isTextFile(context.file),
      isNotTextFile: (context) => !isTextFile(context.file)
    },
    services: {
      getText: async (context) => {
        return (await fetch(context.file.fileUrl as string)).text()
      }
    }
  }
)
