import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useContext,
  Dispatch
} from 'react'
import { UserManager, Client, User, Entity, EntityName } from '@jdlt-ltd/pongo'
import {
  editClient,
  unassignUserFromClient as unassignUserFromClientApiCall,
  createClient,
  addAuth0User,
  editUser,
  assignUsersToRole as assignUsersToRoleApiCall,
  unassignUsersFromRole as unassignUsersFromRoleApiCall
} from '../../services/graphQl/authorisation'
import {
  setLocalStorage,
  getLocalStorage,
  DataContext,
  UserContext
} from '../../utils'
import {
  useUndoNotification,
  NotificationContext,
  useApiService,
  ItemQuery
} from '@jdlt-ltd/pongo'
import { useAuth0 } from '@auth0/auth0-react'

export const Users: FC = () => {
  const { errorNotification, successNotification } =
    useContext(NotificationContext)

  const { idToken } = useContext(UserContext)
  const { user } = useAuth0()

  const {
    clientData,
    userData,
    roleData,
    permissionData,
    setClientData,
    setUserData,
    setRoleData,
    assignUserToClient,
    isLoadingAssigningUserToClient,
    setDataWithUpdatedEntity,
    getRelatedEntitiesForClient,
    getRelatedEntitiesForUser,
    isLoadingClients,
    isLoadingRoles,
    isLoadingUsers,
    isLoadingPermissions,
    initialUserManagerDataFetch,
    listClientsApiQuery,
    listRolesApiQuery,
    listPermissionsApiQuery,
    listUsersApiQuery
  } = useContext(DataContext)

  const [clientFilters, setClientFilters] = useState(
    Object.keys(getLocalStorage('clientFilters')).length
      ? getLocalStorage('clientFilters')
      : {
          active: {
            filterIsActive: true,
            filterValue: { indeterminate: false, checked: true }
          }
        }
  )

  const updateClientFilters = useCallback((filters: any) => {
    setLocalStorage('clientFilters', filters)
    setClientFilters(filters)
  }, [])

  const [userFilters, setUserFilters] = useState(
    Object.keys(getLocalStorage('userFilters')).length
      ? getLocalStorage('userFilters')
      : {
          active: {
            filterIsActive: true,
            filterValue: { indeterminate: false, checked: true }
          }
        }
  )

  const updateUserFilters = useCallback((filters: any) => {
    setLocalStorage('userFilters', filters)
    setUserFilters(filters)
  }, [])

  const [
    {
      data: updatedClient,
      apiQueryFunc: updateClient,
      isLoading: isUpdatingClient,
      isError: isErrorUpdatingClient
    },
    {
      data: roleWithAssignedUsers,
      apiQueryFunc: assignUsersToRole,
      isError: isErrorAssigningUsersToRole,
      isLoading: isLoadingAssigningUsersToRole
    },
    {
      data: newUser,
      apiQueryFunc: addNewUser,
      isError: isErrorAddingUser,
      isLoading: isCreatingNewUser
    },
    {
      data: updatedUser,
      isLoading: isUpdatingUser,
      apiQueryFunc: updateUser,
      isError: isErrorUpdatingUser
    },
    {
      data: newClient,
      isLoading: isCreatingNewClient,
      apiQueryFunc: createNewClient,
      isError: isErrorCreatingNewClient
    }
  ] = [
    useApiService(editClient, [], null, false, idToken as string),
    useApiService(assignUsersToRoleApiCall, [], null, false, idToken as string),
    useApiService(addAuth0User, [], null, false, idToken as string),
    useApiService(editUser, [], null, false, idToken as string),
    useApiService(createClient, [], null, false, idToken as string)
  ]

  const [loadingEntities, setLoadingEntities] = useState<string[]>([])

  useEffect(() => {
    if (initialUserManagerDataFetch?.current) {
      listClientsApiQuery([])
      listRolesApiQuery([])
      listPermissionsApiQuery([])
      listUsersApiQuery([])
      initialUserManagerDataFetch.current = false
    }
  }, [
    initialUserManagerDataFetch,
    listClientsApiQuery,
    listPermissionsApiQuery,
    listRolesApiQuery,
    listUsersApiQuery
  ])

  const removeLoadingEntities = (entities: string[]) =>
    setLoadingEntities((prevLoadingEntities) => {
      return prevLoadingEntities.filter((entity) => !entities.includes(entity))
    })

  const addLoadingEntities = (entities: string[]) =>
    setLoadingEntities((prevLoadingEntities) => {
      return prevLoadingEntities.concat(entities)
    })

  const updateEntityValuesApiCall = (
    apiCall: Function,
    {
      entityId,
      updateValues,
      updateKey
    }: { entityId: string; updateKey: string; updateValues: string[] },
    setStateFunction: Dispatch<React.SetStateAction<any[]>>
  ) => {
    const affectedEntities = [...updateValues, entityId]
    addLoadingEntities(affectedEntities)
    apiCall(idToken, updateValues, entityId)
      .then((updatedEntity: Entity) => {
        setDataWithUpdatedEntity(
          entityId,
          { key: updateKey, value: updatedEntity[updateKey] },
          setStateFunction
        )
        removeLoadingEntities(affectedEntities)
      })
      .catch((err: Error) => {
        removeLoadingEntities(affectedEntities)
        errorNotification({
          title: `Error`,
          message: err.message
        })
      })
  }

  const unassignUserFromClient = async (userIds: string[], clientId: string) =>
    updateEntityValuesApiCall(
      unassignUserFromClientApiCall,
      {
        entityId: clientId,
        updateKey: 'users',
        updateValues: userIds
      },
      setClientData
    )

  const unassignUsersFromRole = async (userIds: string[], roleId: string) =>
    updateEntityValuesApiCall(
      unassignUsersFromRoleApiCall,
      {
        entityId: roleId,
        updateKey: 'users',
        updateValues: userIds
      },
      setRoleData
    )

  useEffect(() => {
    if (updatedClient) {
      successNotification({
        title: `Client ${updatedClient?.name} was updated`,
        autoClose: true,
        timerDuration: 2000
      })
      setClientData((clients) => {
        const updatedClientList = [
          ...clients.filter(
            (existingClient: Client) => existingClient.id !== updatedClient.id
          ),
          {
            ...updatedClient,
            getRelatedEntities: (client: Client) =>
              getRelatedEntitiesForClient(client)
          }
        ]
        return updatedClientList
      })
    }
    if (isErrorUpdatingClient) {
      return errorNotification({
        title: `Error updating new client`
      })
    }
  }, [
    updatedClient,
    successNotification,
    errorNotification,
    isErrorUpdatingClient,
    setClientData,
    getRelatedEntitiesForClient
  ])

  const createClientAndSetData = (name: string) => createNewClient([name])

  useEffect(() => {
    if (newClient) {
      setClientData((clients) => [
        {
          ...newClient,
          active: true,
          getRelatedEntities: (client: Client) =>
            getRelatedEntitiesForClient(client)
        },
        ...clients
      ])
      successNotification({
        title: `Client ${newClient?.name} was created`,
        autoClose: true,
        timerDuration: 2000
      })
    }
    if (isErrorCreatingNewClient) {
      return errorNotification({
        title: `Error creating new client`
      })
    }
  }, [
    newClient,
    successNotification,
    errorNotification,
    isErrorCreatingNewClient,
    setClientData,
    getRelatedEntitiesForClient
  ])

  const assignUserToClientAndSetData = (userIds: string[], clientId: string) =>
    assignUserToClient([userIds, clientId])

  const addUser = (emailAddress: string, name: string) =>
    addNewUser([emailAddress, name])

  useEffect(() => {
    if (newUser) {
      setUserData((users) => [
        ...users,
        {
          ...newUser,
          active: true,
          getRelatedEntities: ({ id }: User) =>
            getRelatedEntitiesForUser(id as string)
        }
      ])
      successNotification({
        title: `Added user ${newUser.name}`,
        autoClose: true,
        timerDuration: 2000
      })
      addNewUser([null])
    }
    if (isErrorAddingUser) {
      errorNotification({
        title: `Error adding user`
      })
    }
  }, [
    newUser,
    addNewUser,
    isErrorAddingUser,
    successNotification,
    errorNotification,
    getRelatedEntitiesForUser,
    setUserData
  ])

  const assignUsersToRoleAndSetData = (
    userIds: string[],
    roleId: string
  ): Promise<void> => assignUsersToRole([userIds, roleId])

  useEffect(() => {
    if (roleWithAssignedUsers) {
      setDataWithUpdatedEntity(
        roleWithAssignedUsers.id,
        { key: 'users', value: roleWithAssignedUsers.users },
        setRoleData
      )
    }
    if (isErrorAssigningUsersToRole) {
      errorNotification({
        title: `Error assinging user to role`
      })
    }
  }, [
    errorNotification,
    isErrorAssigningUsersToRole,
    roleWithAssignedUsers,
    setDataWithUpdatedEntity,
    setRoleData
  ])

  const editClientAndSetData = ({ name, id, active }: Client) =>
    updateClient([id, { name: name, active: active }])

  const [updateClientVars, setUpdateClientVars] = useState<{
    [key: string]: {
      idToken: string
      id: string
      roleDetails: { name: string; active: boolean }
    }
  }>({})

  const [updateUserVars, setUpdateUserVars] = useState<{
    [key: string]: {
      idToken: string
      id: string
      userDetailsToUpdate: { blocked: boolean | undefined }
    }
  }>({})

  const deactivateClient = (client: Client) => {
    const variables = {
      idToken: idToken as string,
      id: client.id as string,
      roleDetails: { name: client.name, active: client.active as boolean }
    }
    setUpdateClientVars((vars) => ({
      ...vars,
      [client.id || '']: variables
    }))
  }

  const deactivateUser = (user: User) => {
    const variables = {
      idToken: idToken as string,
      id: user.id as string,
      userDetailsToUpdate: { blocked: user.blocked }
    }
    setUpdateUserVars((vars) => ({
      ...vars,
      [user.id || '']: variables
    }))
  }

  const deactivateUserOrClient = (userOrClient: User | Client) => {
    const isClient = !!(userOrClient as Client).users
    return isClient
      ? deactivateClient(userOrClient as Client)
      : deactivateUser(userOrClient as User)
  }

  const { prepareToDelete } = useUndoNotification(deactivateUserOrClient)

  const handleDeactivateOrReactivate = (userOrClient: User | Client) => {
    const isClient = !!(userOrClient as Client).users
    const notificationMessage = `${userOrClient.name} will be deactivated`
    if (isClient && userOrClient.active) {
      const variables = {
        idToken: idToken as string,
        id: userOrClient.id as string,
        roleDetails: { name: userOrClient.name, active: userOrClient.active }
      }
      setUpdateClientVars((vars) => ({
        ...vars,
        [userOrClient.id || '']: variables
      }))
      return null
    }
    if (!isClient && userOrClient.active) {
      const variables = {
        idToken: idToken as string,
        id: userOrClient.id as string,
        userDetailsToUpdate: { blocked: false }
      }
      setUpdateUserVars((vars) => ({
        ...vars,
        [userOrClient.id || '']: variables
      }))
      return null
    }
    return isClient
      ? prepareToDelete(userOrClient, '', notificationMessage, `minusCircle`)
      : prepareToDelete(
          { ...userOrClient, blocked: true },
          '',
          notificationMessage,
          `minusCircle`
        )
  }

  useEffect(() => {
    if (updatedUser) {
      setUserData((users) => [
        ...users.filter(({ id }) => id !== updatedUser.id),
        {
          ...(updatedUser as User),
          blocked: updatedUser.blocked,
          active: !updatedUser.blocked,
          getRelatedEntities: ({ id }: User) =>
            getRelatedEntitiesForUser(id as string)
        }
      ])
      successNotification({
        title: updatedUser.blocked
          ? `Deactivated ${updatedUser.name}`
          : `Activated ${updatedUser.name}`,
        autoClose: true,
        timerDuration: 2000
      })
      updateUser([null])
    }
    if (isErrorUpdatingUser) {
      errorNotification({ title: `Error deactivating user` })
    }
  }, [
    updateUser,
    isErrorUpdatingUser,
    updatedUser,
    successNotification,
    errorNotification,
    getRelatedEntitiesForUser,
    setUserData
  ])

  const [updatingUserOrClientIdList, setUpdatingUserOrClientIdList] = useState(
    () => [...Object.keys(updateClientVars), ...Object.keys(updateUserVars)]
  )

  useEffect(() => {
    setUpdatingUserOrClientIdList([
      ...Object.keys(updateClientVars),
      ...Object.keys(updateUserVars)
    ])
  }, [updateClientVars, updateUserVars])

  const handleUpdateClientRequest = (client: string) => (
    <ItemQuery
      key={JSON.stringify(updateClientVars[client])}
      apiQueryFunc={editClient}
      onComplete={(updatedClient) => {
        setClientData((clients) => {
          const updatedClientList = [
            ...clients.filter(
              (existingClient: Client) => existingClient.id !== updatedClient.id
            ),
            {
              ...updatedClient,
              getRelatedEntities: (client: Client) =>
                getRelatedEntitiesForClient(client)
            }
          ]
          return updatedClientList
        })
        setUpdateClientVars((variables) =>
          Object.keys(variables).reduce(
            (acc, key) =>
              key === updatedClient.id
                ? acc
                : { ...acc, [key]: variables[key] },
            {} as { [key: string]: any }
          )
        )
        successNotification({
          title: `Client ${updatedClient.name} was updated`
        })
      }}
      variables={[
        updateClientVars[client].idToken,
        updateClientVars[client].id,
        updateClientVars[client].roleDetails
      ]}
      onError={(error) => {
        errorNotification({
          title: `Error updating new client`
        })
        setUpdateClientVars((variables) =>
          Object.keys(variables).reduce(
            (acc, key) =>
              key === updatedClient.id
                ? acc
                : { ...acc, [key]: variables[key] },
            {} as { [key: string]: any }
          )
        )
      }}
    />
  )

  const handleUpdateUserRequest = (user: string) => (
    <ItemQuery
      key={JSON.stringify(updateUserVars[user])}
      apiQueryFunc={editUser}
      onComplete={(updatedUser) => {
        setUserData((users) => [
          ...users.filter(({ id }) => id !== updatedUser.id),
          {
            ...(updatedUser as User),
            blocked: updatedUser.blocked,
            active: !updatedUser.blocked,
            getRelatedEntities: ({ id }: User) =>
              getRelatedEntitiesForUser(id as string)
          }
        ])
        setUpdateUserVars((variables) =>
          Object.keys(variables).reduce(
            (acc, key) =>
              key === updatedUser.id ? acc : { ...acc, [key]: variables[key] },
            {} as { [key: string]: any }
          )
        )
        successNotification({
          title: updatedUser.blocked
            ? `Deactivated ${updatedUser.name}`
            : `Activated ${updatedUser.name}`
        })
      }}
      variables={[
        updateUserVars[user].idToken,
        updateUserVars[user].id,
        updateUserVars[user].userDetailsToUpdate
      ]}
      onError={(error) => {
        errorNotification({ title: `Error deactivating user` })
        setUpdateUserVars((variables) =>
          Object.keys(variables).reduce(
            (acc, key) =>
              key === updatedUser.id ? acc : { ...acc, [key]: variables[key] },
            {} as { [key: string]: any }
          )
        )
      }}
    />
  )

  return (
    <>
      {updateClientVars &&
        Object.keys(updateClientVars).map(handleUpdateClientRequest)}
      {updateUserVars &&
        Object.keys(updateUserVars).map(handleUpdateUserRequest)}
      <div className="px-4 pb-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 pt-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
            <div className="w-full mx-4 mt-2">
              <h1 className="text-2xl font-semibold text-gray-900">Settings</h1>
            </div>
          </div>
        </div>
      </div>
      <UserManager
        isUpdatingUser={isUpdatingUser}
        updatedUser={updatedUser}
        tabs={['client', 'user', 'role', 'permission'] as EntityName[]}
        currentUser={{ ...user, id: user?.sub, emailAddress: user?.email }}
        setClientFilters={updateClientFilters}
        clientFilters={clientFilters}
        setUserFilters={updateUserFilters}
        userFilters={userFilters}
        userData={userData}
        isLoadingUserData={isLoadingUsers}
        isLoadingClientData={isLoadingClients}
        isEditingClient={isUpdatingClient}
        isCreatingClient={isCreatingNewClient}
        isCreatingUser={isCreatingNewUser}
        assignUserToClient={assignUserToClientAndSetData}
        assignUsersToRole={assignUsersToRoleAndSetData}
        unassignUserFromClient={unassignUserFromClient}
        unassignUsersFromRole={unassignUsersFromRole}
        addUser={addUser}
        editClient={editClientAndSetData}
        deactivateUser={handleDeactivateOrReactivate}
        deactivateOrReactivateClient={handleDeactivateOrReactivate}
        updatedClient={updatedClient}
        isLoadingRoleData={isLoadingRoles}
        isLoadingPermissionData={isLoadingPermissions}
        clientData={clientData}
        setClientData={setClientData}
        roleData={roleData}
        permissionData={permissionData}
        newUser={newUser}
        newClient={newClient}
        loadingEntities={loadingEntities}
        isLoadingAssigningUserToClient={isLoadingAssigningUserToClient}
        isLoadingAssigningUsersToRole={isLoadingAssigningUsersToRole}
        createClient={createClientAndSetData}
        userOrClientDeactivateOrReactivate={updatingUserOrClientIdList}
      />
    </>
  )
}
