import React, { useState, useEffect, useContext, Dispatch } from 'react'
import {
  UserContext,
  DataContext,
  FileOrFolder,
  useGetColumns,
  ClickEventValue,
  createFullPath,
  checkIsDuplicateName,
  getFileOrFolderDropdown,
  getFileAndFolderPath,
  getObjectsForPathAndFormatData,
  encodeUrlPath,
  formatFileAndFolderData,
  getFileExtension,
  useUploadFileWithOverwrite,
  getMediaMimeType,
  mediaPlayerFileExtensionList
} from '../../utils'
import {
  S3ObjectsData,
  renameFolder,
  removeFile,
  removeFolder,
  renameFile,
  getUrlForFileDownload,
  createFolder,
  getObjectsForPath,
  getUrlToViewFileInline
} from '../../services/graphQl'
import {
  Modal,
  DataTable,
  FileUploader,
  Button,
  useUndoNotification,
  NotificationContext,
  LocalSearch,
  SearchResult,
  highlightValueInText,
  Icon,
  useApiService,
  Entity,
  PDFViewer,
  ItemQuery,
  ImageViewer,
  MediaPlayer,
  Alert,
  Slide,
  CarouselModal
} from '@jdlt-ltd/pongo'
import { EditableInput } from './EditableInput'
import { MoveFile } from './MoveFile'
import { NewFolder } from './NewFolder'
import { Breadcrumbs } from '../index'
import { DeleteFile } from './DeleteFile'
import { DeleteFolder } from './DeleteFolder'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { EditableUpload } from './EditableUpload'
import { UrlToViewFileQuery } from './UrlToViewFileQuery'

type NameToUpdate = {
  name: string
  id: string
  type: string
}

const defaultNameToUpdateState = { name: ``, id: ``, type: `folder` }

export const Docs: React.FC<any> = (): JSX.Element => {
  const { clientId } = useParams<{ clientId: string }>()

  const { pathname: fullPath } = useLocation()
  const navigate = useNavigate()

  const [fileAndFolderSearchData, setFileAndFolderSearchData] = useState<
    SearchResult[]
  >([])

  const { path, containsFileName, fileName } = getFileAndFolderPath(
    fullPath,
    clientId || ``
  )

  const {
    idToken,
    activeClient,
    currentFolderPath,
    setCurrentFolderPath,
    currentFolderData,
    setCurrentFolderData,
    sessionId
  } = useContext(UserContext)

  const {
    fetchedClientDataId,
    isLoadingS3Objects,
    listClientS3Objects,
    s3Data,
    setS3Data
  } = useContext(DataContext)

  useEffect(() => {
    setCurrentFolderPath(path)
  }, [setCurrentFolderPath, path])

  const { errorNotification } = useContext(NotificationContext)

  useEffect(() => {
    if (activeClient?.id && fetchedClientDataId?.current !== activeClient.id) {
      // clears previous client objects
      setCurrentFolderData([])
      listClientS3Objects([activeClient.id])
      //Marks the last fetched client objects
      if (fetchedClientDataId) fetchedClientDataId.current = activeClient.id
    }
  }, [
    activeClient,
    listClientS3Objects,
    fetchedClientDataId,
    setCurrentFolderData,
    setS3Data
  ])

  const [
    { data: dataAfterRenameFolder, apiQueryFunc: renameFolderFunc },
    { data: dataAfterRenameFile, apiQueryFunc: renameFileFunc },
    { data: dataAfterCreateFolder, apiQueryFunc: createFolderFunc }
  ] = [
    useApiService(renameFolder, [], null, false, idToken as string),
    useApiService(renameFile, [], null, false, idToken as string),
    useApiService(createFolder, [], null, false, idToken as string)
  ]

  useEffect(() => {
    if (dataAfterRenameFolder) setS3Data(dataAfterRenameFolder)
  }, [dataAfterRenameFolder, setS3Data])

  useEffect(() => {
    if (dataAfterRenameFile) setS3Data(dataAfterRenameFile)
  }, [dataAfterRenameFile, setS3Data])

  const [filesToDelete, setFilesToDelete] = useState<{
    [key: string]: {
      sessionId: string
      clientId: string | undefined
      currentFolderPath: string
      name: string
      idToken: string | null
    }
  }>({})

  const [foldersToDelete, setFoldersToDelete] = useState<{
    [key: string]: {
      sessionId: string
      clientId: string | undefined
      currentFolderPath: string
      name: string
      idToken: string | null
    }
  }>({})

  const deleteFile = (fileOrFolder: FileOrFolder) => {
    const { name } = fileOrFolder

    const requestInfo = {
      sessionId,
      clientId: activeClient?.id,
      currentFolderPath,
      name,
      idToken
    }

    setFilesToDelete((currentFilesToDelete) => ({
      ...currentFilesToDelete,
      [name]: requestInfo
    }))
  }

  const deleteFolder = (fileOrFolder: FileOrFolder) => {
    const { name } = fileOrFolder

    const requestInfo = {
      sessionId,
      clientId: activeClient?.id,
      currentFolderPath,
      name,
      idToken
    }

    setFoldersToDelete((currentFoldersToDelete) => ({
      ...currentFoldersToDelete,
      [name]: requestInfo
    }))
  }

  const { prepareToDelete: prepareToDeleteFile } =
    useUndoNotification(deleteFile)

  const { confirmDelete: confirmDeleteFolder } =
    useUndoNotification(deleteFolder)

  const createNewFolder = (
    folderData: FileOrFolder[],
    folderPath: string,
    newFolderName: string,
    setCreateNewFolder: Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (checkIsDuplicateName(folderData, newFolderName.trim()))
      return errorNotification({
        title: `Error`,
        message: `Folder name already in use in this directory`
      })

    createFolderFunc([activeClient?.id, folderPath, newFolderName.trim()])
    setCreateNewFolder(false)
  }

  useEffect(() => {
    if (dataAfterCreateFolder) {
      setS3Data(dataAfterCreateFolder)
    }
  }, [dataAfterCreateFolder, setS3Data])

  const [newFolderModalOpen, setNewFolderModalOpen] = useState(false)

  const [editableInputModalOpen, setEditableInputModalOpen] =
    useState<boolean>(false)
  const [nameToUpdate, setNameToUpdate] = useState<NameToUpdate>(
    defaultNameToUpdateState
  )
  const [moveFileModalOpen, setMoveFileModalOpen] = useState<boolean>(false)
  const [moveFilePath, setMoveFilePath] = useState<string[]>([])

  useEffect(() => {
    if (s3Data) {
      const { folderData, fileData } = getObjectsForPathAndFormatData(
        s3Data as S3ObjectsData,
        currentFolderPath
      )
      setCurrentFolderData([...folderData, ...fileData])
    } else {
      setCurrentFolderData([])
    }
  }, [s3Data, currentFolderPath, setCurrentFolderData])

  const handleClickRename = ({
    value,
    type
  }: ClickEventValue & { type: string }): void => {
    if (value) {
      setEditableInputModalOpen(true)
      const matchingFileOrFolder = currentFolderData.find(
        ({ name }: { name: string }) => name === value
      )
      if (matchingFileOrFolder)
        setNameToUpdate({ name: matchingFileOrFolder.name, id: value, type })
    }
  }

  const handleSaveName = (updatedName: string, originalName: string): void => {
    if (checkIsDuplicateName(currentFolderData, updatedName))
      return errorNotification({
        title: `Error`,
        message: `Name ${updatedName} already in use in this folder`
      })

    const updatedData = currentFolderData.reduce(
      (dataAcc: FileOrFolder[], currDatum: FileOrFolder) =>
        currDatum.name === nameToUpdate.name
          ? [...dataAcc, { ...currDatum, name: updatedName }]
          : [...dataAcc, currDatum],
      []
    )

    const matchingFileOrFolder = currentFolderData.find(
      ({ name }) => name === originalName
    )
    matchingFileOrFolder?.type === `file`
      ? renameFileFunc([
          sessionId,
          activeClient?.id,
          currentFolderPath,
          originalName,
          updatedName
        ])
      : renameFolderFunc([
          sessionId,
          activeClient?.id,
          currentFolderPath,
          originalName,
          updatedName
        ])
    setEditableInputModalOpen(false)
    setCurrentFolderData(updatedData)
  }

  const handleClickMove = ({ value: fileName }: ClickEventValue): void => {
    if (fileName) {
      const folderPath = createFullPath(currentFolderPath, fileName)
      setMoveFilePath([folderPath])
      setMoveFileModalOpen(true)
    }
  }

  const handleDelete = ({ value }: ClickEventValue): void => {
    const fileOrFolder = currentFolderData.find(
      ({ name }: { name: string }) => name === value
    )

    if (!fileOrFolder) return

    const isFolder = fileOrFolder?.type === `folder`

    isFolder
      ? confirmDeleteFolder(fileOrFolder, currentFolderPath)
      : prepareToDeleteFile(fileOrFolder, currentFolderPath)
  }

  // handle download file

  const [downloadFileUrlRequests, setDownloadFileUrlRequests] = useState<{
    [key: string]: {
      sessionId: string
      clientId?: string
      path: string
      name: string
      idToken: string | null
    }
  }>({})

  const [downloadFileUrls, setDownloadFileUrls] = useState<{
    [key: string]: any
  }>({})

  const handleDownload = (
    { value: name }: ClickEventValue,
    folderPath?: string
  ): void => {
    const request = {
      sessionId,
      clientId: activeClient?.id,
      path: folderPath === undefined ? currentFolderPath : folderPath,
      name,
      idToken
    }

    const fileKey = `${request.path}/${name}`

    setDownloadFileUrlRequests((currentRequests) => ({
      ...currentRequests,
      [fileKey]: request
    }))
  }

  useEffect(() => {
    const requestsToDownload = Object.keys(downloadFileUrlRequests)

    const filesReadyToDownload = Object.keys(downloadFileUrls).filter(
      (fileName) => {
        return requestsToDownload.find((name) => name === fileName)
      }
    )

    if (!!filesReadyToDownload.length) {
      filesReadyToDownload.forEach((fileName) => {
        const linkElement = document.createElement('a')
        linkElement.href = downloadFileUrls[fileName]
        linkElement.download = fileName || 'download'
        linkElement.click()

        // remove request from downloadUrlRequests
        setDownloadFileUrlRequests((currentRequests) => {
          const newRequests = { ...currentRequests }
          delete newRequests[fileName]
          return newRequests
        })
      })
    }
  }, [downloadFileUrls, downloadFileUrlRequests])

  const [viewFileUrlRequests, setViewFileUrlRequests] = useState<{
    [key: string]: {
      sessionId: string
      clientId?: string
      path: string
      name: string
      idToken: string | null
    }
  }>({})

  const [fileUrlsToView, setFileUrlsToView] = useState<{
    [key: string]: {
      fileUrl?: string
      isLoaded?: boolean
      content?: string
    }
  }>({})

  const [filesToView, setFilesToView] = useState<{
    [key: string]: any
  }>({})

  const handleView = (
    { value: name }: ClickEventValue,
    folderPath?: string
  ) => {
    const request = {
      sessionId,
      clientId: activeClient?.id,
      path: folderPath === undefined ? currentFolderPath : folderPath,
      name,
      idToken
    }

    const fileKey = `${request.path}/${name}`

    setViewFileUrlRequests((currentRequests) => ({
      ...currentRequests,
      [fileKey]: request
    }))
  }

  // On the event of a user navigating to a file url in the browser, open the pdf viewer
  useEffect(() => {
    if (containsFileName && fileName) {
      const request = {
        sessionId,
        clientId,
        path,
        name: fileName,
        idToken
      }

      const fileKey = `${request.path}/${fileName}`

      // if url cached, start open file on viewer
      if (downloadFileUrls[fileName]) {
        setFilesToView((currentFiles) => ({
          ...currentFiles,
          [fileKey]: fileName
        }))
      }

      // else request url
      setViewFileUrlRequests((vars) => ({
        ...vars,
        [fileKey]: request
      }))
    }
  }, [
    sessionId,
    path,
    fileName,
    clientId,
    containsFileName,
    idToken,
    downloadFileUrls
  ])

  const filesWithActionInProgress = Object.keys({
    ...downloadFileUrlRequests,
    ...viewFileUrlRequests,
    ...filesToDelete,
    ...foldersToDelete
  })

  const { columns } = useGetColumns({
    handleClickRename,
    handleDelete,
    handleDownload,
    handleClickMove,
    handleView,
    s3Data,
    setS3Data,
    filesWithActionInProgress
  })

  const addFileAndFolderContextMenus = (fileAndFolderData: FileOrFolder[]) => {
    return fileAndFolderData.map((fileOrFolder) => {
      const { name } = fileOrFolder

      const isCompatibleWithViwer = mediaPlayerFileExtensionList.includes(
        getFileExtension(name).toLocaleLowerCase()
      )

      const onContextMenu = getFileOrFolderDropdown({
        fileOrFolder,
        handleClickRename,
        handleDelete,
        handleDownload,
        handleClickMove,
        ...(isCompatibleWithViwer && { handleView })
      })

      return {
        ...fileOrFolder,
        onContextMenu
      }
    })
  }

  const disableUploadFunctionality = !s3Data || isLoadingS3Objects

  useEffect(() => {
    if (s3Data) {
      const s3DataArray = Object.entries(s3Data as S3ObjectsData)

      const formattedS3Data = s3DataArray.reduce(
        (acc: any, currS3DataArray) => {
          const path = currS3DataArray[0]

          const { folders, files } = getObjectsForPath(s3Data, path)

          const { folderData, fileData } = formatFileAndFolderData(
            folders,
            files
          )
          const formatFileOrFolder = (fileOrFolder: FileOrFolder) => {
            const link = fileOrFolder.path
              ? encodeUrlPath(
                  `/docs/${clientId}/${fileOrFolder.path}/${fileOrFolder.name}`
                )
              : encodeUrlPath(`/docs/${clientId}/${fileOrFolder.name}`)
            const { type, iconName: icon } = fileOrFolder
            return {
              entity: { ...fileOrFolder, path, link },
              icon,
              type
            }
          }
          return [
            ...acc,
            ...folderData.map((folder) => {
              return formatFileOrFolder(folder)
            }),
            ...fileData.map(formatFileOrFolder)
          ]
        },
        []
      )
      setFileAndFolderSearchData(formattedS3Data)
    }
  }, [s3Data, clientId])

  const searchResultDisplay = (
    entity: Entity,
    searchValue: string,
    bestMatch?: { value: string; key: string },
    type?: string
  ) => {
    const { path, name } = entity

    const highlightedSearchResults = highlightValueInText(
      name,
      searchValue,
      'text-red-800'
    )

    const fullPath =
      type === 'folder' ? (path ? `${path}/${name}` : name) : path
    const isCurrentLocation = fullPath === currentFolderPath

    return type === 'folder' ? (
      <>
        {path && `${path}/`}
        {highlightedSearchResults}
        {isCurrentLocation && (
          <span className="italic text-gray-400"> (current folder)</span>
        )}
      </>
    ) : (
      <>
        <p>{highlightedSearchResults}</p>
        <div className="text-sm leading-5 text-gray-400">
          {path}
          <span className="italic text-gray-400">
            {!path && '(root folder)'}
            {isCurrentLocation && ' (current folder)'}
          </span>
        </div>
      </>
    )
  }

  const {
    duplicatefilesToUpload,
    setDuplicateFilesToUpload,
    setFilesUpload,
    setOverWrite
  } = useUploadFileWithOverwrite({ s3Data, setS3Data, currentFolderData })

  const handleGetUrlToViewInBrowser = (request: {
    sessionId: string
    clientId?: string
    path: string
    name: string
    idToken: string | null
  }) => {
    const { idToken, sessionId, clientId, path, name } = request

    const fileKey = `${path}/${name}`

    const clearUrlToViewFileRequest = () => {
      setViewFileUrlRequests((currentStateUrlToViewFileRequests) => {
        const newUrlToViewFileRequests = {
          ...currentStateUrlToViewFileRequests
        }
        delete newUrlToViewFileRequests[fileKey]
        return newUrlToViewFileRequests
      })
    }

    return (
      <UrlToViewFileQuery
        key={JSON.stringify(request)}
        apiQueryFunc={getUrlToViewFileInline}
        variables={[idToken, sessionId, clientId, path, name]}
        onComplete={(data) => {
          if (!data.isLoaded) {
            handleDownload({ value: name }, path)
            clearUrlToViewFileRequest()
            return
          }
          setFileUrlsToView((urls) => ({
            ...urls,
            [fileKey]: data
          }))
          setFilesToView((currentFilesToView) => ({
            ...currentFilesToView,
            [fileKey]: name
          }))
          clearUrlToViewFileRequest()
        }}
        onError={(error) => {
          clearUrlToViewFileRequest()
          setFilesToView((currentFilesToView) => {
            const newCurrentFilesToView = { ...currentFilesToView }
            delete newCurrentFilesToView[fileKey]
            return newCurrentFilesToView
          })
          errorNotification({
            title: `Error`,
            message: decodeURIComponent(error)
          })
        }}
      />
    )
  }

  const handleGetUrlForDownload = (request: {
    sessionId: string
    clientId?: string
    path: string
    name: string
    idToken: string | null
  }) => {
    const { idToken, sessionId, clientId, path, name } = request

    const fileKey = `${path}/${name}`

    const clearDownloadUrlRequest = () => {
      setDownloadFileUrlRequests((currentDownloadFileUrlRequests) => {
        const newDownloadUrlRequests = { ...currentDownloadFileUrlRequests }
        delete newDownloadUrlRequests[fileKey]
        return newDownloadUrlRequests
      })
    }

    return (
      <ItemQuery
        key={JSON.stringify(request)}
        apiQueryFunc={getUrlForFileDownload}
        variables={[idToken, sessionId, clientId, path, name]}
        onComplete={(data) => {
          setDownloadFileUrls((urls) => ({
            ...urls,
            [fileKey]: data
          }))
        }}
        onError={(error) => {
          errorNotification({
            title: `Error`,
            message: decodeURIComponent(error)
          })
          clearDownloadUrlRequest()
        }}
      />
    )
  }

  const carouselSlides = Object.keys(filesToView).reduce((acc, fileToView) => {
    const extension = getFileExtension(fileToView)
    if (!fileUrlsToView[fileToView]) return acc
    const { fileUrl = '', isLoaded, content } = fileUrlsToView[fileToView]
    const mimetype = getMediaMimeType(fileToView)

    const imageViewer = (
      <ImageViewer
        cssClasses={['mx-auto', 'w-full']}
        style={{ maxHeight: '80vh' }}
        src={fileUrl}
      />
    )

    const pageScaleSingleSlide = window.innerWidth > 400 ? 0.91 : 0.41

    const playerMap: { [key: string]: JSX.Element } = {
      jpeg: imageViewer,
      png: imageViewer,
      tiff: imageViewer,
      jpg: imageViewer,
      gif: imageViewer,
      ico: imageViewer,
      svg: imageViewer,
      webp: imageViewer,
      txt: (
        <p
          className="max-w-lg mx-auto overflow-auto text-lg"
          style={{ maxHeight: '80vh' }}
        >
          {content}
        </p>
      ),
      pdf: (
        <div style={{ maxHeight: '80vh' }} className="overflow-hidden">
          <PDFViewer
            closable={false}
            isDownload={false}
            pdfUrl={fileUrl}
            pageScale={pageScaleSingleSlide}
          />
        </div>
      )
    }

    const player = playerMap[extension] ? (
      playerMap[extension]
    ) : (
      <MediaPlayer
        style={{ maxHeight: '80vh' }}
        src={fileUrl}
        type={mimetype}
      />
    )

    return [
      ...acc,
      {
        fileUrl,
        fileName: fileToView.split('/').pop(),
        element: isLoaded ? (
          player
        ) : (
          <Alert
            color={'gray'}
            rounded
            message={
              <>
                <div className="mt-2 text-sm leading-5">
                  <p>
                    You may be offline or with limited connectivity. Try
                    downloading instead.
                  </p>
                </div>
                <div className="mt-4">
                  <div className="-mx-2 -my-1.5 flex">
                    <Button
                      border={false}
                      content="Download"
                      size="md"
                      onClick={() => window.open(fileUrl, '_blank')}
                    />
                  </div>
                </div>
              </>
            }
            title={`Couldn't preview file`}
          />
        )
      }
    ]
  }, [] as Slide[])

  const isCarouselModalOpen = Boolean(
    !!Object.keys(filesToView).length &&
      Object.keys(filesToView).length === carouselSlides.length
  )

  return (
    <>
      {viewFileUrlRequests &&
        Object.values(viewFileUrlRequests).map(handleGetUrlToViewInBrowser)}
      {downloadFileUrlRequests &&
        Object.values(downloadFileUrlRequests).map(handleGetUrlForDownload)}
      <EditableUpload
        activeClient={activeClient}
        currentFolderData={currentFolderData}
        duplicatefilesToUpload={duplicatefilesToUpload}
        path={path}
        setDuplicateFilesToUpload={setDuplicateFilesToUpload}
        setFilesUpload={setFilesUpload}
        setOverWrite={setOverWrite}
      />

      <DeleteFile
        filesToDelete={filesToDelete}
        removeFile={removeFile}
        setFilesToDelete={setFilesToDelete}
        setS3Data={setS3Data}
      />

      <DeleteFolder
        foldersToDelete={foldersToDelete}
        removeFolder={removeFolder}
        setFoldersToDelete={setFoldersToDelete}
        setS3Data={setS3Data}
      />
      <Modal
        closable
        visible={newFolderModalOpen}
        onClose={(): void => setNewFolderModalOpen(false)}
        closeOnEsc
      >
        <NewFolder
          createNewFolder={createNewFolder}
          currentFolderData={currentFolderData}
          setNewFolderModalOpen={setNewFolderModalOpen}
        />
      </Modal>
      <CarouselModal
        closable
        visible={isCarouselModalOpen}
        onClose={(): void => {
          setViewFileUrlRequests({})
          setFilesToView({})
        }}
        closeOnEsc
        size="max"
        downloadable
        carousselProps={{ slides: carouselSlides }}
      />
      <Modal
        closable
        visible={moveFileModalOpen}
        onClose={(): void => setMoveFileModalOpen(false)}
        closeOnEsc
      >
        <MoveFile
          moveFilePathList={moveFilePath}
          setMoveFileModalOpen={setMoveFileModalOpen}
          createNewFolder={createNewFolder}
          s3Data={s3Data}
          setS3Data={setS3Data}
        />
      </Modal>
      <Modal
        visible={editableInputModalOpen}
        closeOnEsc={true}
        closable={true}
        onClose={(): void => {
          setEditableInputModalOpen(false)
          setNameToUpdate(defaultNameToUpdateState)
        }}
      >
        <EditableInput
          name={nameToUpdate.name}
          handleSaveName={handleSaveName}
          type={nameToUpdate?.type}
        />
      </Modal>
      <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 pt-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-no-wrap">
            <div className={currentFolderPath === '' ? 'hidden sm:block' : ''}>
              <Breadcrumbs
                currentFolderPath={currentFolderPath}
                clientId={clientId as string}
                activeClientName={activeClient?.name}
              />
            </div>
            <div className="flex-shrink-0 mt-2 ml-4">
              <Button
                disabled={disableUploadFunctionality}
                content="New folder"
                color="gray"
                onClick={(): void => setNewFolderModalOpen(true)}
                cssClasses={['mr-4']}
              />
              <FileUploader
                disabled={disableUploadFunctionality}
                dialogOnClick
                cssClasses={['inline-block']}
                onFilesAccepted={(fileList) => setFilesUpload([...fileList])}
              >
                <Button
                  content="Upload"
                  color="red"
                  disabled={disableUploadFunctionality}
                />
              </FileUploader>
            </div>
          </div>
        </div>
        <div className="w-full px-5 pt-6 md:pt-8">
          <LocalSearch
            searchData={fileAndFolderSearchData}
            searchKeys={['entity.name']}
            onClick={(entity, type) => {
              if (type === 'folder') {
                navigate(entity.link)
                return
              }
              handleView({ value: entity.name }, entity.path)
            }}
            searchResultDisplay={searchResultDisplay}
          />
        </div>
      </div>
      <div className="px-4 pt-4 mx-auto max-w-7xl sm:px-6 md:px-8 sm:pt-6 md:pt-8">
        <FileUploader
          name={currentFolderPath}
          onFilesAccepted={(fileList) => setFilesUpload([...fileList])}
          dropAreaCssClasses={['-m-3', 'p-3']}
          disabled={disableUploadFunctionality}
        >
          <DataTable
            columns={columns}
            data={addFileAndFolderContextMenus(currentFolderData)}
            sortable={true}
            isLoading={isLoadingS3Objects}
            defaultSortValues={{
              sortDirection: `descending`,
              colKey: `type`,
              dataType: `text`
            }}
            urlPath={fullPath}
            shouldFilterAndSortOnTheClient={true}
            emptyTableDescription={
              <div className="justify-center mx-6 my-4 text-gray-400 border-4 border-dashed rounded-lg cursor-pointer hover:text-gray-600 border-gray-250 sm:mx-8 sm:my-6 bg-gray-150 hover:bg-gray-50">
                <FileUploader
                  disabled={disableUploadFunctionality}
                  dialogOnClick
                  onFilesAccepted={(filesList) =>
                    setFilesUpload([...filesList])
                  }
                >
                  <div className="block px-8 py-8">
                    <Icon iconName="cloudUpload" className="h-12 mx-auto" />
                    <p>Add files</p>
                  </div>
                </FileUploader>
              </div>
            }
            striped
          />
        </FileUploader>
      </div>
    </>
  )
}
