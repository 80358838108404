import React, { useContext, Dispatch, useState } from 'react'
import {
  moveFiles as s3MoveFiles,
  S3ObjectsData
} from '../../../services/graphQl/s3'
import {
  getParentFolderPathFromPath,
  getFileOrFolderNameFromPath,
  UserContext,
  getObjectsForPathAndFormatData,
  encodeUrlPath
} from '../../index'
import { Link } from 'react-router-dom'
import { NotificationContext } from '@jdlt-ltd/pongo'
import { checkMultiFilesIsDuplicateName } from '../../fileAndFolderFuncs'

type MoveFileProps = {
  s3Data: S3ObjectsData | null
  setS3Data: Dispatch<React.SetStateAction<S3ObjectsData | null>>
}

export const useMoveFile = ({
  s3Data,
  setS3Data
}: MoveFileProps): {
  startMoveFile: (
    moveFilePathList: string[],
    moveFileDestinationPath: string
  ) => Promise<any>
  isMovingFileOrFolder: boolean
} => {
  const [isMovingFileOrFolder, setIsMovingFileOrFolder] = useState(false)
  const { activeClient, sessionId, idToken } = useContext(UserContext)
  const { errorNotification, successNotification } =
    useContext(NotificationContext)

  const startMoveFile = async (
    moveFilePathList: string[],
    moveFileDestinationPath: string
  ) => {
    const clientId = activeClient?.id ? activeClient.id : ''

    const moveFileParentFolderPath = getParentFolderPathFromPath(
      moveFilePathList[0]
    )

    const moveFileNameList = moveFilePathList.map(getFileOrFolderNameFromPath)

    const { folderData, fileData } = getObjectsForPathAndFormatData(
      s3Data as S3ObjectsData,
      moveFileDestinationPath
    )

    const fileOrFolderDuplicateName = checkMultiFilesIsDuplicateName(
      [...folderData, ...fileData],
      moveFileNameList
    )

    if (s3Data && fileOrFolderDuplicateName)
      return errorNotification({
        title: `Error`,
        message: `File ${fileOrFolderDuplicateName.name} already in use in this directory`
      })

    setIsMovingFileOrFolder(true)
    try {
      const updatedS3Data = await s3MoveFiles(
        idToken as string,
        sessionId,
        clientId,
        moveFileParentFolderPath,
        moveFileNameList,
        moveFileDestinationPath
      )
      setS3Data(updatedS3Data)
      const moveFolderUrlPath = `/${clientId}/docs${
        moveFileDestinationPath ? `/${moveFileDestinationPath}` : ''
      }`
      const linkClassName = 'text-blue-500 underline'

      const fileNames = moveFileNameList.map((name, i) => (
        <li key={`${name}-${i}`}>{name}</li>
      ))

      const title =
        moveFileNameList.length > 1 ? (
          <>
            Moved {moveFileNameList.length} files to {moveFolderUrlPath}
          </>
        ) : (
          <>
            Moved file{' '}
            <Link
              className={linkClassName}
              to={encodeUrlPath(`${moveFolderUrlPath}`)}
            >
              {moveFileNameList[0]}
            </Link>{' '}
            to {moveFolderUrlPath}
          </>
        )

      successNotification({
        autoClose: true,
        timerDuration: 2000,
        closeOnClick: false,
        title,
        isExpand: moveFileNameList.length > 1,
        expandInfo: <ul className="list-disc list-inside">{fileNames}</ul>,
        isPauseOnMouseOrTouchEnter: true
      })

      return 'success'
    } catch (errors: any) {
      errorNotification({
        tite: `Upload error`,
        message:
          (errors as any)[0].message || `An error occurred whilst moving file`
      })
    } finally {
      setIsMovingFileOrFolder(false)
    }
  }

  return { startMoveFile, isMovingFileOrFolder }
}
