import React from 'react'
import './assets/fonts/OpenSans-Regular.ttf'
import './assets/fonts/Raleway-Light.ttf'
import './index.css'
import { TsAndCs, AnimatedLogo, DisplayPage, Payments } from './components'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import {
  UserContextProvider,
  DataContextProvider,
  useLogRocket,
  useGoogleAnalytics,
  Auth0ProviderWithHistory
} from './utils/'

import { NotificationContextProvider } from '@jdlt-ltd/pongo'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Users, Docs } from './components'
import { ActiveClient } from './components/docs/ActiveClient'
import { PaymentResponse } from './components/payments/PaymentResponse'

export const ProtectedRoute = ({ component }: React.PropsWithChildren<any>) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <AnimatedLogo
        className={'w-1/2 mx-auto mt-40 h-1/2 md:w-1/4 md:h-1/4'}
        fullScreen
        text="Loading the next page..."
      />
    )
  })

  return <Component />
}

export const App: React.FC = () => {
  useGoogleAnalytics()
  useLogRocket()

  return (
    <div className="App">
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <UserContextProvider>
            <NotificationContextProvider>
              <DataContextProvider>
                <Routes>
                  <Route path={'/terms-and-conditions'} element={<TsAndCs />} />
                  <Route
                    path={`*`}
                    element={<ProtectedRoute component={DisplayPage} />}
                  >
                    <Route path={`docs`}>
                      <Route path={`:clientId`} element={<ActiveClient />}>
                        <Route path={`*`} element={<Docs />} />
                      </Route>
                    </Route>
                    <Route path={`settings`} element={<Users />} />
                    <Route path={`payments`} element={<Payments />}>
                      <Route
                        path={`:paymentId`}
                        element={<PaymentResponse />}
                      />
                    </Route>
                  </Route>
                </Routes>
              </DataContextProvider>
            </NotificationContextProvider>
          </UserContextProvider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </div>
  )
}

export default App
